


function ReservationDataRight (){

    return(
        <>
        <div>
        </div>
        </>
    )
}

export default ReservationDataRight